import React, { useRef } from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import Image from "@components/shared/sub/image";

import { Container, P } from "@util/standard";
import {
  colors,
  icons,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  MAX_WIDTH,
} from "@util/constants";
import BlocksContent from "../sub/blockContent";
import { useTestimonialQuery } from "@api";
import { CarouselRef } from "antd/lib/carousel";

interface Props {
  sliderContent?: Array<object>;
  customMargin?: string;
  customMobileMargin?: string;
}
const StyledCarousel = styled(Carousel)`
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    bottom: -40px;

    list-style-type: none;

    li {
      margin: 0 0.25rem;

      button {
        display: block;
        width: 1rem;
        height: 1rem;
        padding: 0;

        border: none;
        border-radius: 100%;

        text-indent: -9999px;

        border: 2px solid ${colors.lightBlue};
        border-radius: 12px;
        background-color: ${colors.lightBlue};
      }
    }

    li.slick-active button {
    }
  }
`;
const ImageWrapper = styled.div`
  height: 110px;
  width: 12%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 201px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 80px;
    width: 100px;
  }
`;
const StyledImg = styled(Image)`
  width: 100% !important;
  height: 100% !important;

  img {
    object-fit: contain !important; // !important to override the element style
  }
`;
const BlockContentContainer = styled.div`
  text-align: center;

  p {
    color: black;
    font-size: 25px;
    line-height: 38px;
    font-style: italic;
    margin: auto;
    margin-bottom: 30px;
    width: 70%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      font-size: 18px;
      line-height: 23px;
      width: 90%;
    }
  }
`;
const Arrow = styled.img<{
  right?: boolean;
}>`
  filter: brightness(0.5);
  display: block;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ right }) => (right ? "right: 0px;" : "left: 0px;")};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    // display: none;
    margin: 10px;
  }
`;
const StyledH4 = styled.h4`
  font-family: "header bold";
`;

function TestimonialsSlider(props: Props) {
  const { sanityTestimonialsIndex } = useTestimonialQuery();

  const autoplay = sanityTestimonialsIndex?.autoplay;

  const { customMargin, customMobileMargin } = props;

  // const { sliderContent } = props;
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay ?? false,
    autoplaySpeed: 3000,
    draggable: true,
    adaptiveHeight: true,
  };
  const carouselSlider = useRef<CarouselRef>(null);
  return (
    <Container
      width="80%"
      tabletWidth="100%"
      margin={customMargin ? customMargin : "100px auto"}
      mobileMargin={
        customMobileMargin ? customMobileMargin : "50px auto 0px auto"
      }
      position="relative"
      maxWidth={`${MAX_WIDTH}px`}
    >
      <Container position="absolute" width="100%" height="100%" top="0px">
        <Arrow
          src={icons.leftArrow}
          alt={"Left Arrow"}
          onClick={() => carouselSlider?.current?.prev()}
          width={28}
          height={28}
        />
        <Arrow
          right
          src={icons.rightArrow}
          alt={"Right Arrow"}
          onClick={() => carouselSlider?.current?.next()}
          width={28}
          height={28}
        />
      </Container>
      {sanityTestimonialsIndex && sanityTestimonialsIndex.testimonialsArray && (
        <StyledCarousel {...carouselSettings} ref={carouselSlider}>
          {sanityTestimonialsIndex.testimonialsArray.map(testimonial => {
            if (testimonial == null) return null;
            return (
              <Container key={(testimonial as any)._key}>
                <BlockContentContainer>
                  <BlocksContent
                    blocks={(testimonial as any).testimonialText._rawContent}
                  />
                </BlockContentContainer>
                <Container
                  display="flex"
                  flexWrap="wrap"
                  margin="auto"
                  justifyContent="center"
                >
                  <ImageWrapper>
                    {testimonial.testimonialImage && (
                      <StyledImg data={testimonial.testimonialImage} />
                    )}
                  </ImageWrapper>
                  <Container
                    width="15%"
                    margin="auto 0px auto 20px"
                    mobileWidth="40%"
                  >
                    <StyledH4>{(testimonial as any).name}</StyledH4>
                    <P fontFamily="header bold" color="grey" margin="5px 0px">
                      {(testimonial as any).role}
                    </P>
                    <P fontFamily="header bold" color="grey" margin="5px 0px">
                      {(testimonial as any).organisation}
                    </P>
                  </Container>
                </Container>
              </Container>
            );
          })}
        </StyledCarousel>
      )}
    </Container>
  );
}
export default TestimonialsSlider;
