import { useCustomerLogosQuery } from "@api";
import LayOut from "@components/layout";
import CustomersBlock from "@components/shared/customersBlock";
import FeaturedBlog from "@components/shared/featuredBlogPost";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import TestimonialsSlider from "@components/shared/slider/testimonialsSlider";
import SharedCarousel from "@components/shared/sub/carousel";
import {
  Query,
  SanityCustomersPageConnection,
  SanityLogos,
} from "@graphql-types";
import { useStore } from "@state/store";
import { useRegionChanged } from "@util/hooks";
import { Container } from "@util/standard";
import { graphql, PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

interface Data extends Query {
  altRegions: SanityCustomersPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Customers = (props: Props) => {
  const [logoBlock, setLogoBlock] = useState<SanityLogos>({});
  const { region } = useStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("customers");

  const sanityCustomersPage = props.data.allSanityCustomersPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  const filteredLogos = useCustomerLogosQuery(region.iban);

  useEffect(() => {
    setLogoBlock(filteredLogos);
  }, [filteredLogos]);

  const hasMultipleFeaturedCaseStudies =
    sanityCustomersPage._rawFeaturedCaseStudies &&
    sanityCustomersPage._rawFeaturedCaseStudies.length > 1;

  return (
    <>
      <SEO
        seoData={sanityCustomersPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero heroContent={sanityCustomersPage.hero} />

        {logoBlock && (
          <CustomersBlock
            loadMore
            logos={logoBlock.logos}
            headerText={logoBlock.title}
          />
        )}
        <Container maxWidth="1440px" margin="auto">
          {hasMultipleFeaturedCaseStudies ? (
            <SharedCarousel>
              {sanityCustomersPage._rawFeaturedCaseStudies.map(caseStudy => (
                <FeaturedBlog
                  featuredBlogPost={caseStudy}
                  isCaseStudy
                  height="500px"
                  mobileHeight="400px"
                />
              ))}
            </SharedCarousel>
          ) : (
            <FeaturedBlog
              featuredBlogPost={
                sanityCustomersPage?._rawFeaturedCaseStudies &&
                sanityCustomersPage?._rawFeaturedCaseStudies[0]
              }
              isCaseStudy
            />
          )}
        </Container>

        <TestimonialsSlider
          customMargin="120px auto 170px auto"
          customMobileMargin="50px auto 100px auto"
        />
      </LayOut>
    </>
  );
};

export default Customers;

export const query = graphql`
  query customersQuery($iban: String) {
    allSanityCustomersPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        featuredCaseStudies {
          _id
          _type
          ...sanityCaseStudy
        }
        _rawFeaturedCaseStudies(resolveReferences: { maxDepth: 5 })
      }
    }
    altRegions: allSanityCustomersPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
